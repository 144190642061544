import React from 'react';
// @material-ui/core components
import {makeStyles} from '@material-ui/core/styles';
// @material-ui/icons
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';

import styles from 'assets/jss/material-kit-react/views/landingPageSections/productStyle.js';

const useStyles = makeStyles(styles);

export default function ProductSection() {
    const classes = useStyles();
    return (
        <>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Our Mission</h2>
                        <h5 className={classes.description}>
                            Usage of computer technologies in research and everyday practice of medicine and other biomedical fields is of key importance. Our mission is to create tools and applications that researchers and doctors can use to
                            optimize their workflows and improve the final outcome.
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Our Services</h2>
                        <h5 className={classes.description}>
                            We develop mobile and web applications, taking care of all steps of development - project review and preparation of the specification, development and deployment of the application and continues support.
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Technologies</h2>
                        <h5 className={classes.description}>
                            We are using React Native for mobile development and React/Vue for web development. Back-end of your choice can be used or integrated with existing database systems (SQL or NoSQL databases, cloud databases).
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>
        </>
    );
}
