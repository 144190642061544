import React from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {AnimatedSwitch} from 'react-router-transition';

import logo from './logo.svg';
import './App.css';

import LandingPage from 'views/LandingPage/LandingPage';
import AboutUsPage from 'views/AboutUsPage/AboutUsPage';

const hist = createBrowserHistory();
const App = () => {
    return (
        <Router history={hist}>
            <AnimatedSwitch
                atEnter={{opacity: 0}}
                atLeave={{opacity: 0}}
                atActive={{opacity: 1}}
                className="switch-wrapper">
                <Route path="/about-us" component={AboutUsPage} />
                <Route path="/" component={LandingPage} />
            </AnimatedSwitch>
        </Router>
    );
    return <LandingPage />;
};

export default App;
