import React from "react";
import { title } from "assets/jss/material-kit-react";
import { makeStyles } from "@material-ui/core";

export const ContactDetails = () => {
  const sectionClasses = useSectionStyles();
  return (
    <div className={sectionClasses.section}>
      <h4 className={sectionClasses.description}>
        <div>HARUTYUN DRNOYAN PR BIOMEDICAL APPS</div>
        <div>Reg. No. 65832020</div>
        <div>PIB: 112041819</div>
        <div>Laze Lazarevica 4 , Apt. 4 Novi Sad, 21000, Serbia</div>
        <div>
          Phone: <a href="tel:+381637868438">+381637868438</a>
        </div>
        <div>
          Email:{" "}
          <a href="mailto:contact@biomedicalapps.com">
            contact@biomedicalapps.com
          </a>
        </div>
      </h4>
    </div>
  );
};

const useSectionStyles = makeStyles({
  section: {
    padding: "30px 0",
  },
  title: {
    ...title,
    textDecoration: "none",
    textAlign: "center",
  },
  description: {
    color: "#999",
    textAlign: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px",
  },
});
